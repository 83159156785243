
/*
================================================================================

  "Products" section

================================================================================
*/

.products {
  @include section-padding;
  background-color: #dfdfd7;

  &__container {
    @include container;
  }

  &__header {
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
    text-align: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.6rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    line-height: (48/36);
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
  }
}



/* Item
------------------------------------- */

.products-item {

  // Expanded state
  &.js-expanded {
    .products-item__icon {
      > .icon {
        transform: rotate(90deg);
      }
    }

    .products-item__content {
      display: block;
    }
  }


  &__header {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid $white-color;
    cursor: pointer;

    &:hover {
      .products-item__title {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    margin-right: 1.4rem;

    > .icon {
      width: 1.8rem;
      height: 2.8rem;
      fill: #999;
      transition: $default-transition;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    color: #999;
    font-size: 2rem;
    font-weight: 700;
    line-height: (22/18);
  }

  &__content {
    @include clear-children-margins;
    display: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    line-height: (24/16);
  }
}



/* Slider
------------------------------------- */

.products-slider {
  position: relative;
  margin-bottom: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;


  &__container {

    .swiper-wrapper {
      align-items: center;
    }
  }

  &__item {

    > img {
      display: block;
      width: 100%;
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 1024px
@include mq($until: mq1024px) {
  .products {

    &__title {
      font-size: 2.8rem;
    }
  }
}


// Until 768px
@include mq($until: mq768px) {
  .products {

    &__header {
      margin-bottom: 2rem;
    }

    &__title {
      font-size: 2.4rem;
    }
  }

  .products-item {

    &__icon {

      > .icon {
        width: 1.4rem;
        height: 2.4rem;
      }
    }

    &__title {
      font-size: 1.8rem;
    }
  }
}


// Until 568px
@include mq($until: mq568px) {
  .products-slider {
    padding-left: 0;
    padding-right: 0;

    &__nav-btn {
      display: none;
    }
  }
}
