
/*
================================================================================

  Navigation

================================================================================
*/

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 100;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  width: 41rem;
  height: 100%;
  background-color: $beige-color;
  transition: all 300ms ease;

  // Open state
  &.js-open {
    transform: translateX(0);
  }


  &__container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 0;
    list-style-type: none;
    text-align: center;
  }

  &__item {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $black-color;
    border-bottom: 1px solid transparent;
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    line-height: (32/20);
    text-decoration: none;
    transition: $default-transition;

    &:hover {
      border-bottom-color: $black-color;
    }


    &--active {
      color: $red-color;
      border-bottom: 0;
      cursor: default;
    }
  }

  &__close-btn {
    position: absolute;
    top: 3.5rem;
    right: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      > .icon {
        fill: $red-color;
      }
    }

    > .icon {
      width: 2.1rem;
      height: 2.1rem;
      fill: #c7c7cc;
      transition: $default-transition;
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 768px
@include mq($until: mq768px) {
  .nav {
    width: 100%;

    &__container {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &__item {
      margin-bottom: 2rem;
    }

    &__link {
      font-size: 2.4rem;
    }
  }
}
