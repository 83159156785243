
/*
================================================================================

  "Certificates" section

================================================================================
*/

.cert {
  margin-top: -3rem;
  padding-bottom: 8rem;
  background-color: #dfdfd7;

  &__container {
    @include container;
  }

  &__header {
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    color: #4a4a4a;
    font-size: 3.6rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    line-height: (48/36);
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
    list-style-type: none;
  }

  &__item {
    margin-right: 3rem;
    width: calc(50% - #{3rem / 2});

    &:last-child {
      margin-right: 0;
    }

    > img {
      display: block;
      width: 100%;
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 768px
@include mq($until: mq768px) {
  .cert {

    &__body {
      flex-direction: column;
      align-items: center;
    }

    &__title {
      font-size: 3rem;
    }

    &__item {
      margin-bottom: 3rem;
      margin-right: 0;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
