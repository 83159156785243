
/*
================================================================================

  Footer

================================================================================
*/

.footer {
  margin-top: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $dark-grey-color;

  &__container {
    @include container;
  }

  &__copyright {
    @include clear-children-margins;
    color: $white-color;
    font-size: 1.4rem;
    letter-spacing: 0.15rem;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
}
