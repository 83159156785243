
/*
================================================================================

  "Icon" component

================================================================================
*/

.icon {
  display: block;
}
