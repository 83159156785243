
/*
================================================================================

  Utility Classes

================================================================================
*/

.text-center {
  text-align: center;
}

.bg-beige-light {
  background-color: #dfdfd7;
}

.bg-beige-dark {
  background-color: $dark-beige-color;
}
