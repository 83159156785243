
/*
================================================================================

  "Contact" section

================================================================================
*/

.contact {
  @include section-padding;
  background-color: $beige-color;

  &__container {
    @include container;
  }

  &__header {
    margin-bottom: 8rem;
    text-align: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    color: #4a4a4a;
    font-size: 3.6rem;
    font-weight: 600;
    letter-spacing: 0.15rem;
    line-height: (48/36);
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 98rem;
  }
}



/* Form
------------------------------------- */

.contact-form {
  width: 50%;

  &__field {
  }

  &__input {
    @include placeholder(rgba($black-color, 0.5));
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    height: 5.5rem;
    color: $black-color;
    background-color: rgba(#d1d1c9, 0.6);
    border: 1px solid $white-color;
    border-bottom: 0;
    outline: 0;
    font-size: 1.6rem;
    line-height: normal;
    transition: $default-transition;
    appearance: none;

    &:focus {
      background-color: rgba(#d1d1c9, 0.8);
    }


    &--msg {
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: 10rem;
      border-bottom: 1px solid $white-color;
      resize: none;
    }
  }

  &__submit-btn {
    margin-top: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 5rem;
    color: $black-color;
    background-color: $white-color;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-size: 1.6rem;
    line-height: normal;
    transition: $default-transition;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $white-color;
      background-color: $red-color;
    }
  }
}



/* Address
------------------------------------- */

.contact-address {
  padding-left: 10rem;
  width: 50%;
  font-style: normal;

  &__section {
    margin-bottom: 1.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0.3rem;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    line-height: (24/16);
  }

  &__container {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    line-height: (24/16);
  }

  &__link {
    color: $black-color;
    text-decoration: none;
    transition: $default-transition;

    &:hover {
      color: $red-color;
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 768px
@include mq($until: mq768px) {
  .contact {

    &__header {
      margin-bottom: 4rem;
    }

    &__title {
      font-size: 3rem;
    }

    &__body {
      flex-direction: column;
      align-items: center;
    }
  }

  .contact-form {
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 54rem;
  }

  .contact-address {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}
