
/*
================================================================================

  MQ Breakpoints settings

================================================================================
*/

@import 'sass-mq/mq';

$mq-breakpoints: (
  mq1280px: 1280px,
  mq1024px: 1024px,
  mq768px: 768px,
  mq640px: 640px,
  mq568px: 568px,
  mq480px: 480px,
  mq414px: 414px,
  mq375px: 375px
);
