
/*
================================================================================

  Main

================================================================================
*/

.main {
  @include no-shrink;
}
