
/*
================================================================================

  Variables

================================================================================
*/



/* Grid
------------------------------------- */

$pad: 2rem;



/* Fonts
------------------------------------- */

$base-font-stack: 'Poppins', sans-serif;



/* Colors
------------------------------------- */

$black-color: #000;
$dark-grey-color: #333;
$grey-color: #818181;
$beige-color: #e6e6dd;
$dark-beige-color: #d1d1c9;
$red-color: #e30100;
$white-color: #fff;



/* Transitions
------------------------------------- */

$default-transition: all 200ms ease;
