
/*
================================================================================

  Form mixins

================================================================================
*/



/* Container
------------------------------------- */

@mixin container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $pad;
  padding-right: $pad;
  max-width: calc(121rem + #{$pad * 2});
}



/* No shrink
------------------------------------- */

@mixin no-shrink {
  flex-shrink: 0;
  width: 100%;
}



/* Section padding
------------------------------------- */

@mixin section-padding {
  padding-top: 8rem;
  padding-bottom: 8rem;

  // Until 768px
  @include mq($until: mq768px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  // Until 480px
  @include mq($until: mq480px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
