
/*
================================================================================

  General

================================================================================
*/

:root {
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include font-smoothing;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $black-color;
  font-family: $base-font-stack;
}
