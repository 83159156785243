
/*
================================================================================

  "Slider Button" component

================================================================================
*/

.slider-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;

  > .icon {
    width: 1.8rem;
    height: 3rem;
    fill: $black-color;
  }


  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }
}
