
/*
================================================================================

  "Base" section

================================================================================
*/

.section {
  @include section-padding;

  &--short-p {
    p {
      margin-left: auto;
      margin-right: auto;
      max-width: 87rem;
    }
  }


  &__container {
    @include container;
  }

  &__inner-container {
    @include clear-children-margins;
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    line-height: (28/18);

    h1,
    h2 {
      margin-top: 0;
      margin-bottom: 3rem;
      font-size: 3.6rem;
      font-weight: 700;
      letter-spacing: 0.15rem;
      line-height: (48/36);
      text-align: center;
      text-transform: uppercase;
    }

    ul {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      max-width: 60rem;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 1.5rem;
        padding-left: 3rem;
        color: $dark-grey-color;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 0.06rem;
        line-height: (24/20);

        &:last-child {
          margin-bottom: 0;
        }

        // Marker
        &::before {
          content: '';
          position: absolute;
          top: 0.5rem;
          left: 0;
          width: 1.5rem;
          height: 1.5rem;
          background-color: $dark-beige-color;
          border-radius: 50%;
        }

        a {
          color: $dark-grey-color;
          border-bottom: 1px solid transparent;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }


      &.downloads {
        max-width: 26rem;
      }

      &.featured {
        margin-top: 2.5em;
        margin-bottom: 2.5em;
        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 7rem;
        padding-right: 7rem;
        background-color: $dark-beige-color;
        max-width: none;

        li {
          margin-bottom: 0.5rem;
          color: $black-color;
          font-size: 1.6rem;
          font-weight: 600;
          letter-spacing: 0.05rem;
          line-height: (24/16);

          // Marker
          &::before {
            top: 1rem;
            width: 0.4rem;
            height: 0.4rem;
            background-color: $black-color;
          }

          a {
            color: $dark-grey-color;
            border-bottom: 1px solid transparent;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 1024px
@include mq($until: mq1024px) {
  .section {

    &__inner-container {

      h1,
      h2 {
        font-size: 2.8rem;
      }

      ul {

        &.featured {
          padding-left: 4rem;
          padding-right: 4rem;
        }
      }
    }
  }
}


// Until 768px
@include mq($until: mq768px) {
  .section {

    &__inner-container {

      h1,
      h2 {
        font-size: 2.4rem;
      }

      ul {

        li {
          font-size: 1.7rem;
        }

        &.featured {
          margin-top: 2em;
          margin-bottom: 2em;
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }
}
