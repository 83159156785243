
/*
================================================================================

  "Hero" section

================================================================================
*/

.hero {
  background-image: url('../img/sections/hero/hero-bg.jpg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
         only screen and (min-resolution: 192dpi) {
    background-image: url('../img/sections/hero/hero-bg@2x.jpg');
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 192rem;
  }

  &__inner-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: $pad;
    padding-right: $pad;
    width: 40%;
    background-color: rgba($white-color, 0.5);
  }

  &__desc {
    @include clear-children-margins;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 38rem;
    color: $dark-grey-color;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 0.18rem;
    line-height: (48/30);
    text-align: center;
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 1280px
@include mq($until: mq1280px) {
  .hero {

    &__inner-container {
      width: 50%;
    }

    &__desc {
      font-size: 2.6rem;
    }
  }
}


// Until 768px
@include mq($until: mq768px) {
  .hero {

    &__inner-container {
      width: 100%;
    }

    &__desc {
      font-size: 2.2rem;
    }
  }
}
