
/*
================================================================================

  Text mixins

================================================================================
*/



/* Font smoothing
------------------------------------- */

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* Clears top and bottom margins of
   the first and last child element
   respectively
------------------------------------- */

@mixin clear-children-margins {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}
