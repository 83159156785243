
/*
================================================================================

  Import of all SCSS-files

================================================================================
*/

@charset 'utf-8';

@import 'normalize.css/normalize';
@import 'swiper/css/swiper';

@import 'utils/variables';
@import 'utils/mixins/mixins-form';
@import 'utils/mixins/mixins-grid';
@import 'utils/mixins/mixins-text';

@import 'base/breakpoints';
@import 'base/general';
@import 'base/utility-classes';

@import "components/_icon.scss";
@import "components/_slider-nav-btn.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";
@import "layout/_nav.scss";
@import "sections/_cert.scss";
@import "sections/_contact.scss";
@import "sections/_hero.scss";
@import "sections/_products.scss";
@import "sections/_section.scss";

