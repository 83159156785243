
/*
================================================================================

  Header

================================================================================
*/

.header {
  background-color: $white-color;

  &__container {
    @include container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10.8rem;
  }

  &__logo {
    width: 11rem;

    > img {
      display: block;
      max-width: 100%;
    }
  }

  &__menu-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    width: 4.6rem;
    height: 4.6rem;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      span {
        background-color: $black-color;
      }
    }

    span {
      width: 1.2rem;
      height: 1.2rem;
      background-color: #8e8e93;
      border-radius: 50%;
      transition: $default-transition;
    }
  }
}






/* ================================================================================
   RESPONSIVENESS
================================================================================ */

// Until 768px
@include mq($until: mq768px) {
  .header {

    &__container {
      height: 8rem;
    }

    &__logo {
      width: 9rem;
    }

    &__menu-btn {
      width: 4.2rem;
      height: 4.2rem;

      span {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
